import React, { useMemo } from 'react';
import Typography, {
  Heading1,
  Heading2,
  Heading3,
  Paragraph,
} from '../components/Typography';
import { color, compose, space } from 'styled-system';
import {
  faCreditCard,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import Button from '../components/Button';
import Grid from '../components/Grid';
import HeroSection from '../components/HeroSection';
import Link from '../components/Link';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Section from '../components/Section';
import UncustomizedAbsolutePositioner from '../components/AbsolutePositioner';
import UncustomizedCard from '../components/Card';
import defaultTheme from '../utils/themes';
import propTypes from '@styled-system/prop-types';
import styled from 'styled-components';

const Card = styled(UncustomizedCard).attrs(({ paddingBottom }) => ({
  paddingTop: 32,
  paddingBottom: paddingBottom || 36,
  paddingLeft: 24,
  paddingRight: 24,
}))``;

const HighlightedContent = styled.div(compose(color, space));

HighlightedContent.propTypes = {
  ...propTypes.color,
  ...propTypes.space,
};

HighlightedContent.defaultProps = {
  backgroundColor: 'rgba(72, 78, 181, 0.06)',
  marginLeft: -4,
  marginRight: -4,
  marginTop: 40,
  paddingLeft: 13,
  paddingRight: 13,
  paddingTop: 10,
  paddingBottom: 10,
};

const HighlightedText = styled(Typography).attrs(({ marginBottom }) => ({
  component: 'p',
  color: '#323341',
  fontSize: '0.85rem !important',
  fontWeight: 600,
  lineHeight: '1.2rem',
  marginBottom: marginBottom || '0px',
}))``;

const AbsolutePositioner = styled(UncustomizedAbsolutePositioner).attrs({
  left: 24,
  bottom: 36,
})`
  display: flex;
  flex-direction: column;
`;

const WaysToPayPage = () => {
  return (
    <>
      <SEO
        title='Ways to Pay'
        description={`
                Jacobs Enforcement strives to make repaying your debt as straightforward as possible.
                `}
      />
      <HeroSection paddingTop='8px'>
        <Heading1 marginTop='120px'>Ways to Pay</Heading1>

        <Paragraph>
          Jacobs strive to make repaying your debt as straightforward as
          possible.
        </Paragraph>
      </HeroSection>

      <Section backgroundColor='grey' paddingTop='74px' paddingBottom='80px'>
        <Grid
          ieCardLayout
          justifyItems='stretch'
          gridTemplateColumns={useMemo(
            () => ({
              xs: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr',
            }),
            []
          )}
          gridGap={{
            xs: '20px',
            md: '30px',
            lg: '40px',
          }}
        >
          <Card paddingBottom='71px'>
            <Heading2>Pay Online</Heading2>

            <Paragraph>
              You can make a payment via our online facility by clicking the
              link below.
            </Paragraph>

            <Paragraph>
              You will need your Jacobs reference and your Client Reference to
              login.
            </Paragraph>

            <AbsolutePositioner>
              <Button as='a' href='/web-services/pay-now' icon={faCreditCard}>
                Pay Online
              </Button>
            </AbsolutePositioner>
          </Card>

          <Card>
            <Heading2>Pay via Automated Payment Line</Heading2>
            <Paragraph>
              You can pay via our 24 hour automated payment line.
            </Paragraph>

            <HighlightedText marginBottom='10px'>
              <Link
                href='tel:03300080430'
                textDecoration='none'
                color='headerColor'
                fontSize='24px'
              >
                0330 008 0430
              </Link>
            </HighlightedText>
            <HighlightedText marginBottom='10px'>
              <Heading3 marginTop='16px' marginBottom='8px' fontSize='16px'>
                HMCTS Court Fines and Warrants
              </Heading3>
              <Link
                href='tel:03300563323 '
                textDecoration='none'
                color='headerColor'
                fontSize='24px'
              >
                0330 056 3323
              </Link>
            </HighlightedText>

            <Paragraph>
              You will need your Jacobs reference, current postcode and your
              credit/debit card to hand.
            </Paragraph>
          </Card>

          <Card>
            <Heading2>Pay by Recurrent Card Payments</Heading2>
            <Paragraph>
              With your permission we can take your agreed arrangement payments
              directly from your chosen debit/credit card. This means you don’t
              have to worry about remembering your payments.
            </Paragraph>
            <Paragraph>
              To set this up is simple, just call our office on{' '}
              <Link
                href='tel:03456012692'
                textDecoration='none'
                color='headerColor'
              >
                0345 601 2692
              </Link>{' '}
              or{' '}
              <Link
                href='tel:03300563323 '
                textDecoration='none'
                color='headerColor'
              >
                0330 056 3323
              </Link>{' '}
              for HMCTS Court Fines and Warrants and make sure you have your
              preferred debit/credit card to hand.
            </Paragraph>
          </Card>

          <Card>
            <Heading2>Internet Banking or Standing Order</Heading2>

            <Paragraph marginBottom='-26px'>
              Our Barclays Bank details are:
            </Paragraph>

            <HighlightedContent paddingRight='0px' marginBottom='16px'>
              <HighlightedText>
                Account Name: Jacobs Client Account
              </HighlightedText>

              <HighlightedText>Account Number: 13753085</HighlightedText>

              <HighlightedText>Sort Code: 20-50-51</HighlightedText>
            </HighlightedContent>
            <Paragraph>
              You must quote your Jacobs reference with every payment to ensure
              your money is correctly allocated to your account.
            </Paragraph>
          </Card>

          <Card>
            <Heading2>Pay by Post</Heading2>

            <Paragraph>
              You can send us cheques, postal orders or bankers drafts to:
            </Paragraph>

            <HighlightedContent marginTop='28px' marginBottom='16px'>
              <HighlightedText>Jacobs</HighlightedText>

              <HighlightedText>6 Europa Boulevard</HighlightedText>

              <HighlightedText>Birkenhead</HighlightedText>
              <HighlightedText>Merseyside</HighlightedText>
              <HighlightedText>CH41 4PE</HighlightedText>
            </HighlightedContent>

            <Paragraph>
              You must write your full name and Jacobs reference on the back of
              every payment to ensure your money is correctly allocated to your
              account.
            </Paragraph>
          </Card>

          <Card paddingBottom='122px'>
            <Heading2>Pay by Payment Card</Heading2>

            <Paragraph>
              You can call our office on{' '}
              <Link href='tel:0345 601 2692' textDecoration='none'>
                0345 601 2692
              </Link>{' '}
              or{' '}
              <Link href='tel:0330 056 3323' textDecoration='none'>
                0330 056 3323
              </Link>{' '}
              for HMCTS Court Fines and Warrants to request a Payment Card which
              can be used at any Post Office or Payzone store.
            </Paragraph>

            <AbsolutePositioner>
              <Button
                as='a'
                href='https://www.payzone.co.uk/consumers/'
                target='__blank'
                marginBottom={16}
                width='240px'
                icon={faMapMarkerAlt}
              >
                Payzone Locator
              </Button>

              <Button
                as='a'
                href='https://www.postoffice.co.uk/branch-finder'
                target='__blank'
                width='240px'
                icon={faMapMarkerAlt}
              >
                Find Post Office
              </Button>
            </AbsolutePositioner>
          </Card>
        </Grid>
      </Section>
    </>
  );
};

WaysToPayPage.propTypes = {
  theme: PropTypes.object,
};

WaysToPayPage.defaultProps = {
  theme: defaultTheme,
};

export default WaysToPayPage;
