import styled from 'styled-components';
import { position } from 'styled-system';
import propTypes from '@styled-system/prop-types';

const AbsolutePositioner = styled.div.attrs({ position: 'absolute' })(position);

AbsolutePositioner.propTypes = {
    ...propTypes.position
};

export default AbsolutePositioner;